<template>
	<div class="tab-text">
    	<div class="vx-row mb-6" style="width:50%" v-if="false">
        	<div class="vx-col sm:w-1/3 w-full flex items-center">
            	<span>Period</span>
        	</div>
        	<div class="vx-col sm:w-2/3 w-full">
				<multiselect
					class="selectExample"
					v-model="period"
					:options="optionPeriod"
					:multiple="false"
					:allow-empty="false"
					:group-select="false"
					:max-height="120"
					:limit="3"
					placeholder="Type to search"
					track-by="ID"
					label="period"
					:searchable="false"
				>
					<template slot="singleLabel" slot-scope="props">
						<span class="option__desc">
							<span class="option__title">fiscal year : {{ props.option.fiscal_year }} & period : {{ props.option.period }}</span>
						</span>
					</template>
					<template slot="option" slot-scope="props">
						<div class="option__desc">
							<span class="option__title">fiscal year : {{ props.option.fiscal_year }} & period : {{ props.option.period }}</span>
						</div>
					</template>
            	</multiselect>
        	</div>
    	</div>
		<div class="vx-row mb-6" style="width: 50%">
			<div class="vx-col sm:w-1/6 w-full">
				<span>Posting Date</span>
			</div>
			<div class="vx-col sm:w-5/6 w-full">
				<datepicker :inline="false" v-model="postingDate" placeholder="Select Posting Date" :disabled-dates="{from: new Date()}"></datepicker>
			</div>
		</div>
		<div class="vx-row mb-6" style="width: 50%">
			<div class="vx-col sm:w-1/6 w-full">
				<span>File</span>
			</div>
			<div class="vx-col sm:w-5/6 w-full">
				<input
				class="inputx"
				type="file"
				v-if="uploadReady"
				ref="file"
				name="file"
				accept=".xls, .xlsx"
				/>
			</div>
		</div>
		<div class="vx-row mb-6" style="width: 50%">
			<div class="vx-col sm:w-1/3 w-full flex items-center"></div>
			<div class="vx-col sm:w-2/3 w-full">
				<vs-button class="ml-auto mt-2" v-on:click="handleSubmit">Submit</vs-button>
			<!-- <vs-button
						class="ml-4 mt-2"
						type="border"
						color="warning"
						v-on:click="handleDownload"
						>Download Template</vs-button> -->
			</div>
		</div>
		<vs-alert v-if="errorMessage.length > 0" color="danger">
			<template v-for="dt in errorMessage">
				<div v-bind:key="dt" class="mb-2">{{ dt }}</div>
			</template>
		</vs-alert>
		<div class="vx-row mb-12">
			<div class="core vx-col md:w-1/1 w-full mb-base">
				<vs-table
					search
					stripe
					border
					description
					:sst="true"
					:data="table.data"
					:max-items="table.length"
					:total="table.total"
					@search="handleSearch"
					@change-page="handleChangePage"
					@sort="handleSort"
				>
					<template slot="header">
						<vs-dropdown vs-trigger-click class="cursor-pointer">
							<div class=" p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
								<span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of {{ this.table.total }}</span>
								<feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
							</div>
							<vs-dropdown-menu>
								<vs-dropdown-item
									v-for="item in table.limits"
									:key="item"
									@click="handleChangelength(item)"
									>
									<span>{{ item }}</span>
								</vs-dropdown-item>
							</vs-dropdown-menu>
						</vs-dropdown>
					</template>
					<template slot="thead">
						<vs-th>No</vs-th>
						<vs-th sort-key="file">File</vs-th>
						<vs-th>Description</vs-th>
						<vs-th sort-key="date">Date</vs-th>
						<vs-th>User</vs-th>
						<vs-th>Status</vs-th>
						<vs-th>Action</vs-th>
					</template>
					<template slot-scope="{ data }">
						<vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
							<vs-td>{{ indextr + 1 }}</vs-td>
							<vs-td :data="data[indextr].file"><a href="#" v-on:click="downloadFileAwsS3(tr.file)">{{ getFilename(tr.file) }}</a></vs-td>
							<vs-td :data="data[indextr].description">{{data[indextr].description}}</vs-td>
							<vs-td :data="data[indextr].time">{{ dateFormatGMT7(tr.time) }}</vs-td>
							<vs-td>{{ tr.username }}</vs-td>
							<vs-td>
								Total Document : {{ tr.document }} <br>
								Document Process : {{ tr.document_process }} <br>
								Document Success : {{ tr.document_success }} <br>
								Document Failed : {{ tr.document_failed }} <br>
								Detail Process : {{ tr.document_activity_process }} <br>
								Detail Success : {{ tr.document_activity_success }} <br>
								Detail Failed : {{ tr.document_activity_failed }}
							</vs-td>
							<vs-td>
								<vs-button class="mt-2 ml-2" color="primary" type="border" icon-pack="feather" icon="icon-search" @click="showDetail(tr)"></vs-button>
							</vs-td>
						</vs-tr>
					</template>
				</vs-table>
				<vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage"/>
			</div>
		</div>
		<vs-popup fullscreen title="Log Details" :active.sync="detail">
			<div class="vx-row mb-6">
				<div class="vx-col w-full" v-if="detail">
					<datatableDetail :selected="selected"></datatableDetail>
				</div>
			</div>
		</vs-popup>
  	</div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import moment from 'moment'
import datatableDetail from "./datatable-detail.vue";
export default {
	components: {
		Datepicker,
		datatableDetail
	},
	mounted() {
		// this.getOptionPeriod();
		// this.disabledDates.from = new Date();
	},
	data() {
		return {
			uploadReady: true,
			errorMessage: [],
			detail: false,
			selected: null,
			table: {
				data: [],
				length: 10,
				page: 1,
				search: "",
				order: "id",
				sort: "desc",
				total: 0,
				totalPage: 0,
				totalSearch: 0,
				limits: [10, 25, 50, 100, "All"],
				start: 1,
				end: 0,
			},
			period: {},
			postingDate: null,
			optionPeriod: [{}],
			disabledDates:{}
		};
	},
	methods: {
		getFilename(path) {
			return path.split("/")[5];
		},
		dateFormatGMT7(date) {
            if (date) {
				// ini karena datanya berformat `date time`, bukan `datetimezone`
				// jadi GMT(+7) ditambah +7 =>utc offset = 14
                return moment(String(date)).format('DD/MM/YYYY HH:mm')
            }
        },
		getOptionPeriod() {
			this.$http.get("/api/v1/master/accounting-period").then(resp => {
				this.optionPeriod = resp.data.records;
				this.period = resp.data.records[0];
			});
		},
		handleDownload() {
		// window.open(process.env.VUE_APP_CDN_ADDRESS+'/'+process.env.VUE_APP_CDN_BUCKET+'/templates/TemplateCustomer.xlsx');
		},
		async handleSubmit() {
			this.errorMessage = [];
			var file = this.$refs.file.files[0];
			if (file == null) {
				this.$vs.notify({
					color: "danger",
					title: "Form Validation",
					text: "File is required",
					position: "top-right",
					iconPack: "feather",
					icon: "icon-x-circle",
				});
			} else if(this.postingDate == null || this.postingDate == undefined || this.postingDate == "" || this.postingDate == 1) {
				this.$vs.notify({
					color: "danger",
					title: "Form Validation",
					text: "Please select posting date",
					position: "top-right",
					iconPack: "feather",
					icon: "icon-x-circle",
				});
			} else {
				var dateNow = new Date();
				var now =
				dateNow.getFullYear() +
				"" +
				(dateNow.getMonth() + 1) +
				"" +
				dateNow.getDate();
				now +=
				+"" +
				dateNow.getHours() +
				"" +
				dateNow.getMinutes() +
				"" +
				dateNow.getSeconds();
				this.formData = new FormData();
				this.formData.append("file", file);
				this.formData.append("user_agent", navigator.userAgent);
				this.formData.append("file_name", now + "_" + file.name);
				this.formData.append("url", "/api/v1/import/new-so-to-inv");
				this.formData.append("posting_date", moment(this.postingDate).format('YYYY-MM-DD'));
				
				// this.$vs.loading();
				this.$vs.notify({
					color: "success",
					title: "Success",
					text: "Processing your upload on backgound",
					position: "top-right",
					iconPack: "feather",
					icon: "icon-x-circle",
				});
				this.uploadReady = false;
				
				this.$http
				.post("/api/v1/import/new-so-to-inv", this.formData, {
					headers: {
					"Content-Type": "multipart/form-data",
					},
				})
				.then((resp) => {
					console.log(resp);
					this.$vs.loading.close();
					if (resp.status == "success") {
						this.$nextTick(() => {
							this.uploadReady = true;
						});
						this.getData();
					} else {
						console.log(resp.data);
						this.errorMessage = resp.data;
						this.$vs.notify({
							color: "danger",
							title: "Error",
							text: resp.message,
							position: "top-right",
							iconPack: "feather",
							icon: "icon-x-circle",
						});
					}
				})
				.catch((error) => {
					console.log(error);
					this.$vs.notify({
					color: "danger",
					title: "Error",
					text: error,
					position: "top-right",
					iconPack: "feather",
					icon: "icon-x-circle",
					});
				});
			}
		},
		handleSearch(searching) {
			this.table.search = searching;
			this.table.page = 1;
			this.getData();
		},
		closeDetail() {
			this.detail = false;
		},
		handleChangePage(page) {
			this.table.page = page;
			this.getData();
		},
		handleSort(key, active) {
			this.table.order = key;
			this.table.sort = active;
			this.getData();
		},
		handleChangelength(val) {
			this.table.length = val == "All" ? this.table.total : val;
			this.table.page = 1;
			this.getData();
		},
		showDetail(data) {
			this.selected = data.id;
			this.detail = true;
		},
		getData() {
			this.$vs.loading();
			this.$http
				.get("/api/v1/import/history", {
				params: {
					length: this.table.length,
					page: this.table.page,
					search: this.table.search,
					order: this.table.order,
					sort: this.table.sort,
					type: "sales-order-to-invoice",
				},
				})
				.then((resp) => {
				if (resp.status == "success") {
					this.table.total = resp.data.total_record;
					this.table.totalPage = resp.data.total_page;
					this.table.totalSearch = resp.data.total_record_search;
					this.table.length = resp.data.total_record_per_page;
					this.table.data = resp.data.records;
					this.setStartEnd();
					this.$vs.loading.close();
				} else {
					console.log(resp.message);
				}
			});
		},
		setStartEnd() {
		let valStart =
			this.table.length * this.table.page - this.table.length + 1;
		if (valStart > this.table.total) {
			valStart = 1;
		}
		if (this.table.total == 0) {
			valStart = 0;
		}
		let valEnd = this.table.length * this.table.page;
		if (valEnd > this.table.total) {
			valEnd = this.table.total;
		}
		if (this.table.totalSearch < this.table.total) {
			valEnd = this.table.totalSearch;
		}

		this.table.start = valStart;
		this.table.end = valEnd;
		},
	},
  	watch: {},
	computed: {
		setPage: {
			get() {
				return 1;
			},
			set(val) {
				this.handleChangePage(val);
			},
		},
	},
};
</script>

 <style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>