<template>
  <vx-card title="Import Sales Order To Invoice">
    <vs-tabs>

      <vs-tab label="Sales Order To Invoice">
        <div class="tab-text">
          <so-to-invoice></so-to-invoice>
        </div>
      </vs-tab>
      <vs-tab label="Customer Return Reference">
        <div class="tab-text">
          <sr-reference></sr-reference>
        </div>
      </vs-tab>
      <vs-tab label="Payment">
        <div class="tab-text">
          <payment></payment>
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import SoToInvoice from "./so_to_invoice";
import SrReference from './sr_reference.vue';
import Payment from "./payment.vue"
export default {
  components: {
    "so-to-invoice" : SoToInvoice,
    "sr-reference" : SrReference,
    "payment" : Payment
  }
};
</script>